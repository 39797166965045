import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';

import Franken1 from '../images/gallery/FrankensteinCards-1.jpg'
import Franken2 from '../images/gallery/FrankensteinPromo-24.jpg'
import Franken3 from '../images/gallery/FrankensteinPromo-71.jpg'
import Franken4 from '../images/gallery/FrankensteinPromo-52.jpg'
import Franken5 from '../images/gallery/FrankensteinPromo-15.jpg'
import Franken6 from '../images/gallery/FrankensteinPromo-38.jpg'


import '../styles/imageGallery.css'


// const useStyles = makeStyles(theme => ({
//   root: {
//     display: 'flex',
//     flexWrap: 'wrap',
//     justifyContent: 'space-around',
//     backgroundColor: theme.palette.background.paper,
//     overflowY: 'none'
//   },
//   gridList: {
//     width: 500,
//     height: 450,
//     overflowY: 'none'
//   },
//   override: {
//     overflowY: 'none'
//   }
  
// }));

export default function ImageGridList() {

  return (

    <div class="container">

	<h1 class="heading">Step Back in Time and into the life of Mary Shelley to Celebrate a New Year</h1>

	<div class="gallery">

		<div class="gallery-item">
            <span class="caption">
        		<h2>Victor Frankenstein</h2>
            <p class="desc">
            Every life contains a series of cycles. <br></br><br></br>

            The wheels of fate spin in every possible direction. <br></br><br></br>

            Dr. Victor Frankenstein’s life has gone through

            rapid changes since giving birth to his creation,

            so he is represented by the Wheel of Fortune. <br></br><br></br>


            This card means that you will be exploring 

            His life in the midst of this major shift.

            It will be a shift in his career opportunities and

            luck in his personal life. <br></br><br></br>


            It might also mean Great misfortune and unforeseen set-backs. <br></br><br></br>

            I’ll let you be the judge which it is.              
            </p>
        	</span>
			<img class="gallery-image" src={Franken1} alt="Victor Frankenstein"/>
		</div>

		<div class="gallery-item">
            <span class="caption">
        		<h2>Mary Shelley</h2>
            <p class="desc">
            The creatrix herself. <br></br><br></br>

            The Author, Mary Shelley. <br></br><br></br>

            The Author is traditionally associated with

            maternal influence and creative abundance.

            Mary has had success and challenges with both. <br></br><br></br>

            It also represents the creation of new life and art. <br></br><br></br>

            The shadow of the card can allude to

            moments of stagnation

            and domestic challenges.              
            </p>
        	</span>
			<img class="gallery-image" src={Franken2} alt="Mary Shelley"/>
		</div>

		<div class="gallery-item">
            <span class="caption">
        		<h2>The Monster</h2>
            <p class="desc">
            What makes any creature a human? <br></br><br></br>

            Are any of us born with a sense of our own purpose,

            sense of meaning? <br></br><br></br>


            Is being born without that sense fair? <br></br><br></br>

          
            That’s the journey of The Monster

            His journey for self-discovery is grounded in the exploration of

            what is and is not just and fair.

            He is searching for balance and equilibrium.

            He is discovering that each action has its own consequences. <br></br><br></br>


            The shadow of this card is that

            such an exploration can often lead to tragedy,

            despair and a strong sense of imbalance.
            </p>
        	</span>
			<img class="gallery-image" src={Franken3} alt="people holding umbrellas on a busy street at night lit by street lights and illuminated signs in Tokyo, Japan"/>
		</div>

		<div class="gallery-item">
            <span class="caption">
        		<h2>Percy Shelley</h2>
            <p class="desc">
            Percy Bysshe Shelly, The Author’s husband...The Lover. <br></br><br></br>

            Percy is a man with many complicated relationships

            outside of his marriage to Mary. <br></br><br></br>

            Claire...Byron… his writing.

            He is torn about his feelings for others

            and how they impact his work and his connection to Mary. <br></br><br></br>


            The card’s shadow concerns itself with difficult choices in partnership. <br></br><br></br>

            Percy  has some choices to make

            with regards to his future and his marriage.
            </p>
        	</span>
			<img class="gallery-image" src={Franken4} alt="car interior from central back seat position showing driver and blurred view through windscreen of a busy road at night"/>
		</div>

		<div class="gallery-item">
            <span class="caption">
        		<h2>Lord Byron</h2>
            <p class="desc">
            The demon, the seducer, George Gordon Byron. <br></br><br></br>

            He is the central figure in this menagerie of characters,

            at least in his own mind. <br></br><br></br>

            The card is about sensual pleasure, lust and deception.

            
            Powerful energies for a creative soul,

            but the card’s shadow is addiction and enslavement. <br></br><br></br>

            Byron wants to consume life and the lives of the people around him.
              </p>
        	</span>
			<img class="gallery-image" src={Franken5} alt="back view of woman wearing a backpack and beanie waiting to cross the road on a busy street at night in New York City, USA"/>
		</div>

		<div class="gallery-item">
            <span class="caption">
        		<h2>Claire Clairmont</h2>
            <p class="desc">
            The Dreamer, Claire Clairmont, <br></br><br></br>

            Mary’s half-sister. <br></br><br></br>

            The moon represents the world of the imagination

            and the subconscious. <br></br><br></br>

            Claire wants to exist in the realm of her sister

            and her friends, but the literary life is not her gift. <br></br><br></br>

            

            The shadow of the card is deception and illusion. <br></br><br></br>

            Claire is involved in many complicated relationships,

            (Conspiratorial)

            but she is also harbouring a secret that will disrupt her world.
            </p>
        	</span>
			<img class="gallery-image" src={Franken6} alt="man wearing a black jacket, white shirt, blue jeans, and brown boots, playing a white electric guitar while sitting on an amp"/>
		</div>

	</div>

</div>
  );
}