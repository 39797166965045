import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NewsletterRibbon from '../components/NewsletterRibbon';


import ImageGallery from '../components/imagegallery'

import Layout from "../components/layout"
import SEO from "../components/seo"

import BackgroundImage from 'gatsby-background-image'


const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    productionsWrapper: {
      paddingBottom: "1em",
      maxWidth: "75rem",
      margin: "20px auto",
      display: "flex",
      flexDirection: "column"
    },
    showsWrapper: {
      paddingBottom: "1em",
      maxWidth: "60rem",
      margin: "20px auto",
      display: "flex",
      flexDirection: "column"
    },
    button: {
      margin: theme.spacing(1),
    },
    jumbo: {
      height: "100vh",
      backgroundSize: "cover"
    },
  }));

  export const query = graphql`
    query {
      jumbo: file(relativePath: { eq: "frankensteinBallHeader.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `

export default function CenteredGrid( { data }) {
    const classes = useStyles();
  
    return (
      <Layout>
      <SEO title="Frankenstein's Ball" />
      {/* <div class="jumbotron-wrapper-frankenstein-ball"> */}
      <BackgroundImage 
        Tag="section"
        className={classes.jumbo}
        fluid={data.jumbo.childImageSharp.fluid}
        backgroundColor={`#040e18`}
        >
          <div class="jumbo-inner-wrapper-frankenstein">
              <div>
                <h1 class="jumbo-title-wine">
                    Frankenstein's Ball
                </h1>
                <h5 class="subtitle-wine">An Immersive New Year's Eve Experience</h5>
                {/* <br></br><br></br>
                December 29, 30 & 31
                </h5>
                
                <div class="wine-buttons">
                  <a title="Get Tickets" href="https://www.eventbrite.com/o/found-stages-18287682913" class="button button--bigger blackButton--border">
                    Get Tickets
                  </a>
                  <a title="Find out more about Them!" href="#" class="button button--medium blackButton--border">
                  Frankenstein's Funeral
                  </a>
              </div> */}
              </div>
          </div>
        </BackgroundImage>
      {/* </div> */}

      {/* <Grid container spacing={3} className={classes.productionsWrapper}> */}
        <div>
            <div class="frankenstein-content-block">
              <h3 class="frankenstein-description">
              Frankenstein’s Ball invites audiences into the eccentric and extravagant world of Mary Shelley’s contemporaries as she struggles to find the right ending to her novel on New Year’s Eve.
              </h3>
            </div>  
          </div>
          <div class="lesspadding-title-wrapper">
              <div class="productions-title">
                  A New Year's Eve to Remember
              </div>
          </div>
            <div class="podplay-row">
                <iframe class="i-frame" title="The Making of Frankenstein's Ball" src="https://www.youtube.com/embed/vBozljTKwGs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                {/* <iframe class="i-frame" title="Behind the Music of Frankenstein's Ball" src="https://www.youtube.com/embed/5O070TSEuik" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
            </div>

          
      {/* </Grid> */}
      <NewsletterRibbon />
      <ImageGallery />
    </Layout>
    )
}
